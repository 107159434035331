import { Link } from "react-scroll";

import ExperienceProps from "types/ExperienceProps";

interface ExperiencesInterface {
  experienceData: ExperienceProps[];
  isHoveredStyle: (skill: string) => boolean;
  onSkillOrIDClick: (skill: string) => void;
}

const Experiences: React.FC<ExperiencesInterface> = ({experienceData, isHoveredStyle, onSkillOrIDClick}) => {
  return (
    <section className="dark-bg" id="experience">
        <h1>My <b>Experience</b></h1>
        <ul>
          {experienceData.map((job, i) => (
            <li id={job.id+i} key={job.id+i} className={`job-item ${isHoveredStyle(job.id+i) ? 'hover' : ''}`}>
              <Link 
                to="skills" 
                smooth="easeInOutQuad"
                duration={500}
                offset={-60}
                onClick={() => onSkillOrIDClick(job.id+i)}
              >
                <div className="job-header">
                  <img className="job-image" src={job.imgPath} alt="Job" />
                  <div className="job-info">
                    <h4 className="job-position">{job.position}</h4>
                    <h6 className="job-dates">{job.dates}</h6>
                  </div>
                </div>
                <ul>
                  {job.bullets.map((experienceSnippet, i) => (
                      <li key={i}>{experienceSnippet}</li>
                  ))}
                </ul>
              </Link>
            </li>
          ))}
        </ul>
      </section>
  );
}

export default Experiences;