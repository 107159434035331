import { Link } from "react-scroll";

import typescript from "icons/typescript.svg";
import python from "icons/python.svg";
import cpp from "icons/cpp.svg";
import csharp from "icons/csharp.svg";
import soup from "icons/soup.svg";
import firebase from "icons/firebase.svg";
import tailwind from "icons/tailwind.svg";
import react from "icons/react.svg";
import django from "icons/django.svg";

import SkillProps from "types/SkillProps";

interface SkillInterface {
  skillData: SkillProps[];
  isHoveredStyle: (skill: string) => boolean;
  scrollToID: (skill: string) => string;
  onSkillOrIDClick: (skill: string) => void;
}

const Skills: React.FC< SkillInterface > = ({ skillData, isHoveredStyle, scrollToID, onSkillOrIDClick }) => {
  
  const skillToModule: { [skill: string]: any } = {
    typescript: typescript,
    python: python,
    tailwind: tailwind,
    react: react,
    django: django,
    firebase: firebase,
    cpp: cpp,
    soup: soup,
    csharp: csharp
  };

  return (
    <section className="light-bg" id="skills">
        <h1>My <b>Skills</b></h1>
        <div className="box">
          {skillData.map((skill) => (
            <Link
              to={scrollToID(skill.skill)}
              className={`color-modified-button ${isHoveredStyle(skill.skill) ? 'hover' : ''}`}
              onClick={() => onSkillOrIDClick(skill.skill)}
              smooth="easeInOutQuad"
              duration={500}
              offset={-60}
              key={skill.skill}
            >
              <img src={skillToModule[skill.skill]} className="color-modified-svg" alt={skill.skill}/>
              <p className="color-modified-text">{skill.name}</p>
            </Link>
          ))}
        </div>
      </section>
  );
}

export default Skills;