const Intro: React.FC< { introData: string }> = ({ introData }) => {
  return (
    <main id="intro">
        <div className="image-column">
          <img src="/media/profile-450.png" alt="profile"/>
        </div>
        <header>
            <h1>
              Hi, I'm <b>Jonathan</b><br/>
              <b>Full Stack</b> <span className="hollow">Developer</span><br/>
              Based in <b>New York City</b>
            </h1>
            <p>{introData}</p>
        </header>
      </main>
  );
}

export default Intro;
