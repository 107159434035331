import "App.css"
import { useState } from "react";

import NavBar from "components/NavBar";
import Intro from "components/Intro";
import Experiences from "components/Experiences";
import Skills from "components/Skills";
import Projects from "components/Projects";
import Footer from "components/Footer";

import jsonData from "text/content.json";
import JsonContent from 'types/JsonContent';

let content = jsonData as JsonContent;

function App() {
  const skillToIDs: { [skill: string]: Set<string>; } = {};
  const IDToSkills: { [skill: string]: Set<string>; } = {};

  // Cache the top-most element in the page that we will scroll to
  const skillToLexicographicalFirstID: { [skill:string]: string } = {}

  // Create reverse mapping of skills to projects/experiences that use them
  function addSkillsToMapping(skills: string[], id: string) {
    for (const skill of skills) {
      if (!skillToIDs[skill]) {
        skillToIDs[skill] = new Set<string>();
      }
      skillToIDs[skill].add(id);

      if (!skillToLexicographicalFirstID[skill]) {
        skillToLexicographicalFirstID[skill] = id;
      }

      let idComesFirst = id.localeCompare(skillToLexicographicalFirstID[skill]) === -1;
      if (idComesFirst) {
        skillToLexicographicalFirstID[skill] = id;
      }
    }
  }

  content.projects.forEach((project, idx) => {
    const distinctID = project.id+idx
    addSkillsToMapping(project.skills, distinctID)
    IDToSkills[distinctID] = new Set<string>(project.skills)
  });

  content.experiences.forEach((experience, idx) => {
    const distinctID = experience.id+idx
    addSkillsToMapping(experience.skills, distinctID);
    IDToSkills[distinctID] = new Set<string>(experience.skills);
  })
  
  // State to manage what skill or project/experience ID is selected
  const [selectedSkillOrID, setSelectedSkillOrID] = useState<string | null>(null);

  const handleSkillOrIDClick = (skillOrID: string) => {
    setSelectedSkillOrID(skillOrID);
  }

  // Is string skill or ID
  const isSkill = (skillOrID: string) => { 
    return isNaN(+skillOrID[skillOrID.length - 1]);
  }

  /* Make a skill appear hovered if a selected project or experience uses that skill.
     Make a project or experience appear hovered if it uses the selected skill. */
  const isHoveredStyle = (skillOrID: string): boolean => {
    if (!selectedSkillOrID) return false;

    if (isSkill(selectedSkillOrID)) {
      return skillToIDs[selectedSkillOrID].has(skillOrID);
    } else {
      return IDToSkills[selectedSkillOrID].has(skillOrID);
    }
  }

  const scrollToID = (skill: string): string => {
    return skillToLexicographicalFirstID[skill];
  }

  return (
    <>
      <NavBar />
      <Intro introData={content.intro}/>
      <Experiences 
        experienceData={content.experiences} 
        isHoveredStyle={isHoveredStyle}
        onSkillOrIDClick={handleSkillOrIDClick}
      />
      <Skills 
        skillData={content.skillsToName}
        isHoveredStyle={isHoveredStyle}
        scrollToID={scrollToID}
        onSkillOrIDClick={handleSkillOrIDClick}
      />
      <Projects 
        projectData={content.projects} 
        isHoveredStyle={isHoveredStyle}
        onSkillOrIDClick={handleSkillOrIDClick}
      />
      <Footer/>
    </>
  );
}

export default App;
