import { Link } from "react-scroll";

import ProjectProps from "types/ProjectProps";
import LinkIcon from "components/LinkIcon";

interface ProjectsInterface {
  projectData: ProjectProps[];
  isHoveredStyle: (skill: string) => boolean;
  onSkillOrIDClick: (skill: string) => void;
}

const Projects: React.FC< ProjectsInterface > = ({projectData, isHoveredStyle, onSkillOrIDClick}) => {
  return (
    <section className="dark-bg" id="projects">
        <h1>My <b>Projects</b></h1>
        <ul className="projects">
          {projectData.map((project, i) => (
            <li key={project.id+i} id={project.id+i}>
              <div className="project">
                <a className="image-column" href={project.githubURL} >
                  <img src={project.imgPath} alt="Section"/>
                </a>
                <div className={`info-column`}>
                  <div className={`hover-area ${isHoveredStyle(project.id+i) ? 'hover' : ''}`}>
                    <Link 
                      onClick={() => onSkillOrIDClick(project.id+i)}
                      to="skills"
                      smooth="easeInOutQuad"
                      duration={500}
                      offset={-60}
                    >
                      <h1><b>0{i+1}</b></h1>
                      <h3>{project.title}</h3>
                      <p>{project.description}</p>
                    </Link>
                  </div>
                  <a href={project.githubURL}>
                    <LinkIcon />
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>
  );
}

export default Projects;