import { useState } from 'react';
import { Link } from "react-scroll";

function NavBar() {
  const [isVisibile, setIsVisible] = useState<boolean>(false);

  const hideMenu = () => {
    setIsVisible(false);
  };

  function convertRemToPixels(rem: number) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

  return (
    <div className="container">
      <div className={`${isVisibile ? "cover_show" : "cover_hide"}`} onClick={hideMenu} />
      <div className="content">
        <div className="left">
          <Link to="intro" smooth={true} duration={500} offset={-convertRemToPixels(3.5)}>
            <img className="logo" src="/media/jc-180.svg" alt="logo icon"/>
          </Link>
        </div>
        <div className="right">
          <img
            className="hamburger"
            src="/media/hamburger.svg"
            onClick={() => setIsVisible(!isVisibile)}
            alt="hamburger icon"
          />
          <div className={`${isVisibile ? "menu_show": "menu_hide"}`} onClick={hideMenu}>
            <div className="sections">
              <span className="section">
                <Link
                  to="experience"
                  smooth="easeInOutQuad"
                  duration={500}
                  offset={-convertRemToPixels(3.5)}
                  onClick={hideMenu}
                >
                  Experience
                </Link>
              </span>
              <span className="section">
                <Link
                  to="skills"
                  smooth="easeInOutQuad"
                  duration={500}
                  offset={-convertRemToPixels(3.5)}
                  onClick={hideMenu}
                >
                  Skills
                </Link>
              </span>
              <span className="section">
                <Link
                  to="projects"
                  smooth="easeInOutQuad"
                  duration={500}
                  offset={-convertRemToPixels(3.5)}
                  onClick={hideMenu}
                >
                  Projects
                </Link>
              </span>
              <span className="section">
                <a href="/media/resume.pdf" target="_blank">Resume</a>
              </span>
              <a className="section" href="https://github.com/Jonathan-Carrasco">
                <img className="github_icon" src="/media/github.svg" alt="github icon"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
